<template>
  <section class="m-new m-scroll" ref="scroll">
    <!-- 头部导航 -->
    <m-header>
      <div slot="right">
        <img src="@/assets/public/icon_search.png"  alt="search" class="right-img">
        <router-link to="/en/cart">
          <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
          <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
      </div>
    </m-header>

    <!-- 预售 -->
    <div class="new-title">New Arrivals</div>

    <!-- 左滑商品 -->
    <IndexNew :data="newGoodsList" :loading="nloading" />

    <!-- 滚动切换 -->
    <div v-for="item in newTypeList" :key="item.id">
      <index-cell :title="item.title_en" value="Show all" :to="`/en/subnew?id=${item.id}&title=${item.title_en}`"
      class="title" />
      <goods-columns @cartClick="handleCart" :data="item.goods_list" :columns="1"
      :loading="tloading" />
    </div>

    <van-tabs scrollspy offset-top="26vw" sticky :ellipsis="false"  title-active-color="#ED2A24" title-inactive-color="#000000"
    style="border-top:10px solid #f1f1f1">
      <van-tab :title="item.title_en" v-for="(item, index) in newClassify" :key="index">
        <index-cell :title="item.title_en" value="Show all" :to="`/en/subnew?id=${item.id}&title=${item.title_en}`" class="title" />
        <goods-columns @cartClick="handleCart" :data="item.goodsList" :columns="2" :loading="loading"></goods-columns>
      </van-tab>
    </van-tabs>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MHeader      from '@/components/en/m-header.vue'
import MDot         from '@/components/en/m-dot.vue'
import IndexCell    from '@/components/en/index-cell.vue'
import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import IndexNew     from '@/components/en/index-new.vue'

import { getNewGoodsList, getNewTypeList, getTypeGoodsList, getClassifyList } from '@/api/en/newproduct.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ MHeader, MDot, IndexCell,  GoodsColumns, MSku, MAnimate, CartFlex, IndexNew },
  data(){
    return {
      tab:0,          // tabs选中的面板
      loading:false,
      nloading:false,
      tloading:false,
      skuModal:false,
      start:false,
      start_dom:null,
      dotAnimate:false,
      cartNum:0,
      newGoodsList: [],
      newTypeList: [],
      newClassify: [],
      goodsId: ''
    }
  },

  mounted(){

  },

  methods:{
    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getNewGoodsListHandle() {
      this.nloading = true
      getNewGoodsList().then(res => {
        if(res) {
          this.newGoodsList = res.data
        }
      }).finally(() => {
        this.nloading = false
      })
    },
    getNewTypeListHandle() {
      this.tloading = true
      getNewTypeList().then(res => {
        if(res) {
          res.data.forEach(el => {
            el.goodsList = []
          })
          this.newTypeList = res.data
          this.newTypeList.forEach(el => {
            el.goods_list.forEach(it => {
              it.pre_info = {
                is_pre: 0
              }
            })
            // this.getTypeGoodsListHandle(el.id, el)
          })
        }
      }).finally(() => {
        this.tloading = false
      })
    },
    getTypeGoodsListHandle(id, node = null) {
      // 获取类别下的商品
      this.tloading = true
      getTypeGoodsList(id,{column_id:2}).then(res => {
        if(res) {
          if(node) {
            node.goodsList = res.data.data
          }
        }
      }).finally(() => {
        this.tloading = false
      })
    },
    getClassifyListHandle() {
      // 获取分类
      this.loading = true
      getClassifyList().then(res => {
        if(res) {
          res.data.forEach(el => {
            el.goodsList = []
          })
          if (this.$store.state.isMiniProgram) {
              this.newClassify = res.data.filter(item => {
                  return  item.id != 34 && item.id != 35 && item.id != 36 && item.id != 37 && item.id != 38 && item.id != 45 && item.id != 46 && item.id != 49 && item.id != 50 && item.id != 53 && item.id != 55
              });
          }else {
           this.newClassify = res.data
          }
          this.newClassify.forEach(el => {
            this.getTypeGoodsListHandle(el.id, el)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.getNewGoodsListHandle()
    this.getNewTypeListHandle()
    this.getClassifyListHandle()
  }
}
</script>

<style lang="less" scoped>
.right-img{width:20px;height:20px;margin:6px}
@import './style.less';
</style>